<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
    <div>
        
        <bread-crumbs :items="items"></bread-crumbs>
        
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
            <v-col cols="12" class="mt-10">
                <v-row>
                    <v-col cols="2">
                        <label>اختر الشهر</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="month"
                            :options="months"
                            label="اختر الشهر"
                            style="width:200px;"
                            @change="getEmployees()"
                        ></b-form-select>
                            <b-input-group-addon style="margin-right:-30px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="2">
                        <label>السنة</label>
                        <b-form-input class="inborder"
                        label="السنة"
                        v-model="year"
                        type="number"
                        style="width:200px;"
                        @change="getEmployees()"
                        >{{ year }}</b-form-input>
                    </v-col>
                    <!-- <v-col cols="8" :style="`text-align:`+lang.lalgin">
                        <b-button class="btn-sm btn-success pa-1 m-2" @click="exportE('e')" style="min-width:150px;">تصدير اكسل</b-button>
                        <b-button class="btn-sm btn-danger pa-1 m-2" style="display:none;min-width:150px;">طباعة PDF</b-button>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
        <v-row style="margin:auto 30px;margin-top:-5px;">
            <v-col cols="12" :style="`direction: `+lang.dir+`;`">
                <b-table-simple>
                    <thead>
                        <tr>
                            <th class="text-center backBlack">{{ lang.emp_name }}</th>
                            <th class="text-center backBlack">{{lang.remain_salary}}</th>
                            <th class="text-center backBlack">{{lang.deduct}}</th>
                            <th class="text-center backBlack">{{lang.paid_in_advanced}}</th>
                            <th class="text-center backBlack">{{lang.custdy}}</th>
                            <th class="text-center backBlack">{{lang.card_percentage}}</th>
                            <th class="text-center backBlack">{{lang.invoice_percentage}}</th>
                            <th class="text-center backBlack">{{lang.salary_total}}</th>
                            <th class="text-center backBlack">{{lang.notes}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in rsalary" :key="index">
                            <td>{{item.emp_name}}</td>
                            <td>{{item.stotal}}</td>
                            <td>{{item.discounts}}</td>
                            <td>{{item.padvanced}}</td>
                            <td>{{item.bonuce}}</td>
                            <td>{{item.ptotal}}</td>
                            <td>{{item.ipercentage}}</td>
                            <td>{{item.paidsalary}}</td>
                            <td>{{item.notes}}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-col>
        </v-row>
    </div>

        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ExportsPrint from '@/components/exports-print.vue'
export default {
  components: { ExportsPrint,breadCrumbs, TabsComp, HeaderPortrate, Footer },
    name: 'salary-table',
    data() {
        return {
            employees:[],
            cardsrows: [],
            invoicerows: [],
            rewarded: [],
            payadvanced: [],
            deducts: [],
            custodies: [],
            salaryregisterd: [],
            endofservice: 0,
            months: [
                {text: "January",value:1},
                {text: "February",value:2},
                {text: "March",value:3},
                {text: "April",value:4},
                {text: "May",value:5},
                {text: "June",value:6},
                {text: "July",value:7},
                {text: "August",value:8},
                {text: "September",value:9},
                {text: "October",value:10},
                {text: "November",value:11},
                {text: "December",value:12},
            ],
            month: '',
            year: 2022,
            rsalary: [],
            ppsalary: [],
        }
    },
    created() {
        this.getMonth();
        this.getEmployees();
    },
    methods: {
        preparedata(){
            let exdata = {
                list: "salaryTableList",
                title: this.lang.salary_table,
                data: [],
            }
            for(let i=0;i<this.rsalary.length;i++){
                exdata.data.push(this.rsalary[i]);
            }
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = 'عن شهر '+this.month+' لسنة '+this.year;
            this.$refs.exportbar.edate = '';
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        exportE(type){
            const post = new FormData();
            post.append("export","salary");
            post.append("type",type);
            post.append('month',this.month);
            post.append('year',this.year);
            axios.post(
                this.$store.state.SAMCOTEC.e_path , post
            ).then((response) => {
                const res = response.data;
                // // // console.log(res);
                window.open('../api/'+res.url,'_blank');
            })
        },
        getMonth(){
            let tm = new Date();
            this.month = parseFloat(tm.getMonth()) + parseFloat(1);
            this.year = tm.getFullYear()
        },
        getEmployees() {
            const post = new FormData();
            post.append("type" , "getAllEmpTable");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[month]',this.month);
            post.append('data[year]',this.year);
            
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.rsalary = [];
                const res = response.data.results.data;
                for(let i=0;i<res.length;i++){
                    
                    this.rsalary.push(res[i]);
                    
                }
                // // // console.log("asdfasdf",res);
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function() {
            return {
                    text: 'مسير الرواتب',
                    disabled: true,
                    to: '/emp/view',
                }
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
    }
}
</script>
